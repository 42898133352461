.journey {
  display: flex;
  align-items: center;
  gap: calc(var(--spacing) / 2);
  background: white;
  padding: var(--spacing);
  border-radius: var(--border-radius);
  min-height: 65px;
}

.journey b {
  margin-left: auto;
}
