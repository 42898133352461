:root {
  --spacing: 20px;
  --border-radius: 4px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root,
.App {
  height: 100%;
}

body {
  padding: var(--spacing);
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing);
}

.journeys {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
  width: 100%;
  max-width: 400px;
}

button {
  cursor: pointer;
  background: white;
  color: black;
  padding: 5px 10px;
  border: none;
  border-radius: var(--border-radius);
  font-size: 1.2rem;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
